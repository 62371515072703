import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputControl from "../../../shared/components/InputControl"; // Importing a custom InputControl component
import { Stack, Typography } from "@mui/material";
import TableRowComponent from "./TableRowComponent";
import { Loader } from "../../../shared";
import { LoadingButton } from "@mui/lab";

// Vertical border style
const verticalBorderStyle = {
  border: 0,
  borderRight: 1,
  borderColor: "#E6E6E6",
};

// Horizontal border style
const horizontalBorder = { borderBottom: "1px solid #E6E6E6 !important" };

// Main component that renders the collapsible table
const CollapsibleSettingsTable = ({
  title,
  state,
  tableData,
  isLoading,
  validator,
  handleSearchClear,
  handleSearch,
  handleRowChange,
  handleSaveUpdates,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "Account Name",
        accessor: "account_name",
        minWidth: 210,
      },
      {
        Header: "Account Code",
        accessor: "account_code",
        minWidth: 116,
        align: "center",
      },
      {
        Header: "Account Code",
        accessor: "custom_account_code",
        minWidth: 142,
      },
      {
        Header: "Description",
        accessor: "description",
        minWidth: 360,
      },
      {
        Header: "Tax Rate",
        accessor: "tax_rate",
        minWidth: 142,
      },
      {
        Header: "Tracking Name 1",
        accessor: "tracking_name1",
        minWidth: 180,
      },
      {
        Header: "Tracking Option 1",
        accessor: "tracking_option1",
        minWidth: 180,
      },
      {
        Header: "Tracking Name 2",
        accessor: "tracking_name2",
        minWidth: 180,
      },
      {
        Header: "Tracking Option 2",
        accessor: "tracking_option2",
        minWidth: 180,
      },
    ],
    []
  );

  const { searchValue } = state;
  return (
    <TableContainer
      component={Paper}
      sx={{
        maxWidth: "100%",
        overflowX: "auto",
      }}
    >
      {/* Stack for search input and Save button */}
      <Stack gap="16px" p={2} direction="row" alignItems="center" sx={{
        position: "sticky",
        left: 0
      }}>
        <InputControl
          type="table-search"
          name="searchText"
          placeholder="Search"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          onClear={handleSearchClear}
        />
        <LoadingButton
          variant="contained"
          size="small"
          sx={{ height: "40px" }}
          onClick={handleSaveUpdates}
          disabled={state.isSettingsUpdated}
          loading={state.isSaving}
        >
          Save
        </LoadingButton>
      </Stack>

      {/* Table component */}
      <Table aria-label="collapsible table">
        {/* Table Head */}
        <TableHead>
          <TableRow sx={horizontalBorder}>
            {/* Header for the first two columns */}
            <TableCell
              component="th"
              scope="row"
              colSpan={2}
              sx={verticalBorderStyle}
            >
              <Typography fontWeight={600}>BTill</Typography>
            </TableCell>
            {/* Header for the title column */}
            <TableCell component="th" scope="row" colSpan={7}>
              <Typography fontWeight={600}>{title.toUpperCase()}</Typography>
            </TableCell>
          </TableRow>
          {/* Table row for column headers */}
          <TableRow>
            {/* Mapping through columns to render header cells */}
            {columns.map((column) => {
              return (
                <TableCell
                  key={column.accessor}
                  align={column?.align ?? "left"}
                  sx={{
                    p: "10px 8px",
                    minWidth: column?.minWidth ?? 1,
                    flex: 1,
                    ...(column.accessor === "account_code"
                      ? verticalBorderStyle // Apply vertical border style to account_code column
                      : {}),
                  }}
                >
                  {column.Header} {/* Display column header */}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {/* Mapping through table-data to render rows */}
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <Loader />
              </TableCell>
            </TableRow>
          ) : tableData.length > 0 ? (
            tableData.map((row) => (
              <TableRowComponent
                {...{ key: row.id, title, row, handleRowChange, validator }}
              /> // Render Row component for each row in dummyData
            ))
          ) : (
            <TableRow sx={{ bgcolor: "#F7F7F7" }}>
              <TableCell colSpan={5} align="center">
                No matches found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CollapsibleSettingsTable;
