import React, { useContext, useEffect } from "react";
import { DashboardUi } from "..";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { StaffSales } from "./StaffSales";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../../store";
import { useTabs } from "../../../shared";

export const DashboardContainer = () => {
  const { pathname } = useLocation();
  const { search } = useLocation();
  const navigate = useNavigate();
  const isStoreAdmin = pathname.includes("store-admin");
  const { appState } = useContext(AppContext);
  const {
    currentTabs: { dashboard: currentTab },
  } = appState;
  const { setCurentTab } = useTabs();

  const handleChange = (event, newValue) => {
    navigate({
      pathname: isStoreAdmin ? "/store-admin" : "",
      search: newValue,
    });
  };

  return (
    <>
      <TabContext value={search}>
        <Box
          className="sticky-Tab no-margin"
          sx={{ borderBottom: 1, borderColor: "divider", top: "64px" }}
        >
          <TabList onChange={handleChange}>
            <Tab label="Dashboard" value="" />
            {/* {isStoreAdmin && <Tab label="Staff Sales" value="?staffSales" />} */}
          </TabList>
        </Box>
        <TabPanel sx={{ p: "16px 32px" }} value="">
          {/* <DashboardUi /> */}
          <h4>Dashboard not available</h4>
        </TabPanel>
        <TabPanel sx={{ p: "16px 32px" }} value="?staffSales">
          {isStoreAdmin && <StaffSales />}
        </TabPanel>
      </TabContext>
    </>
  );
};
